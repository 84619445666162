import {Controller} from '@hotwired/stimulus';
import {application} from 'src';

import { displayElement, hideElement, getCSSProperty, addClass, removeClass } from '@fretadao/f-js-dom';
import { isEmptyArray } from '@fretadao/f-js-utils';

application.register('remote-voucher', class extends Controller {
  LI_CLASSES = "px-3 py-2 cursor-pointer hover:bg-gray-lt"
  LI_NOT_FOUND_CLASSES =  "px-3 py-2"

  static targets = [
    'unitPriceField',
    'resultList',
    'textInput',
    'valueField',
    'inputWrapper',
  ]

  static values = { list: Array, companyId: Number }

  connect() {
    document.addEventListener('click', ({ target }) => {
      const clickOutside = !(this.inputWrapperTarget.contains(target))
      if(clickOutside) {
        this.#hideList();
      }
    })

    const voucherId = this.valueFieldTarget.value;
    if(voucherId) {
      this.#getVoucher(voucherId)
        .then((data) => {
          this.textInputTarget.value = data.name;
          this.#toggleInputReadonly(data.unit_price_cents);
        })
    }
  }

  onVoucherChange({ target }) {
    const selected = this.listValue.find((item) => item.id == target.value);

    this.#updateUnitPrice(selected.unit_price);
    this.#toggleInputReadonly(selected.unit_price_cents);
  }

  onInputChange({ target }) {
    this.#getVouchers(target.value)
      .then((data) => {
        this.listValue = data;
      })
  }

  listValueChanged() {
    if(isEmptyArray(this.listValue)) {
      const errorMessage = `<li class="${this.LI_NOT_FOUND_CLASSES}">Nenhum resultado encontrado</li>`
      this.resultListTarget.innerHTML = `<ul>${errorMessage}</ul>`;

      return
    }

    const voucherList = this.#buildList(this.listValue);
    this.resultListTarget.innerHTML= `<ul>${voucherList}</ul>`

    const inputWidth = getCSSProperty(this.textInputTarget, 'width');
    this.resultListTarget.style.width = inputWidth;

    this.#showList();
  }

  setVoucher({ target }) {
    const selectedId = target.getAttribute('value')
    this.textInputTarget.value = target.innerHTML;
    this.valueFieldTarget.value = selectedId;

    this.valueFieldTarget.dispatchEvent(new Event('change'));
    this.#hideList();
  }

  #toggleInputReadonly(voucherUnitPrice) {
    if(voucherUnitPrice !== 0 ){
      this.unitPriceFieldTarget.readOnly = true;
      addClass(this.unitPriceFieldTarget, 'Form-input--readOnly')
    } else {
      this.unitPriceFieldTarget.readOnly = false;
      removeClass(this.unitPriceFieldTarget, 'Form-input--readOnly')
    }
  }

  #showList() {
    displayElement(this.resultListTarget);
  }

  #hideList() {
    hideElement(this.resultListTarget);
  }

  #buildList(data) {
    return data.map((voucher) => {
      return `<li value=${voucher.id} class="${this.LI_CLASSES}" data-action="click->remote-voucher#setVoucher">${voucher.name}</li>`
    }).join('')
  }

  #getVoucher(id) {
    return fetch(`/api/v1/companies/${this.companyIdValue}/vouchers/${id}.json`)
      .then((response) => {
        return this.#handleRequestResponse(response).json();
      })
  }

  #getVouchers(query) {
    return fetch(`/api/v1/companies/${this.companyIdValue}/vouchers?q=${query}`)
      .then((response) => {
        return this.#handleRequestResponse(response).json();
      })
  }

  #updateUnitPrice(price) {
    this.unitPriceFieldTarget.value = price;

    // Dispatch event to trigger callbacks on event type `input`
    this.unitPriceFieldTarget.dispatchEvent(new Event('input'));
  }

  #handleRequestResponse(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }
})
